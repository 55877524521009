


























































import Vue from "vue";

export default Vue.extend({
  name: "BannerSection",
  data() {
    return {
      slide: 0,
      sliding: false,
    };
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
});
